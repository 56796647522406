<template>
  <div
    :style="{paddingTop: `${data.style.pagePaddingTop? data.style.pagePaddingTop : '0'}px`, paddingRight: `${data.style.pagePaddingRight? data.style.pagePaddingRight : '0'}px`, paddingBottom: `${data.style.pagePaddingBottom? data.style.pagePaddingBottom : '0'}px`, paddingLeft: `${data.style.pagePaddingLeft? data.style.pagePaddingLeft : '0'}px`}">
    <video muted :loop='data.style.loop == undefined ? true : data.style.loop' :src="data.style.videoUrl" width="100%"
      :style="{borderRadius: data.style.chamfer == 'circle' ? '10px' : ''}"
      :autoplay="data.style.voluntarilyPlay" :poster="data.style.coverImg" preload @loadedmetadata='loadedmetadata' :controls='false'>
    </video>
  </div>
</template>

<script>
export default {
  name: 'videoTmp',
  props: {
    data: {
      type: Object,
      default: {}
    }
  },
  data() {
    return {
    }
  },
  methods: {
    loadedmetadata(e) {
      console.log('视频组件22', e)
      this.data.style.height = e.target.videoHeight
      this.data.style.width = e.target.videoWidth
      this.$emit('setTemp', this.data)
    }
  },
}
</script>

<style scoped>
</style>
