<template>
  <div class="phoneBox" style="overflow-y: auto" id="phoneBox">
    <div id="preview" class="phone" style="position: relative" :style="{minHeight:`${scrollContainer}px`, backgroundColor:isImmersiveTop ? (pageDataObj.bgColor||'#f5f5f5') : '#f5f5f5',backgroundImage:(isImmersiveTop && pageDataObj.bgUrl) ? `url(${pageDataObj.bgUrl})` : ''}">
      <div @click.stop="pageSetup" class="phoneStartBox" :class="{phoneStartBox2:isImmersiveTop}">
        <el-image style="width: 100%; height: 100%" :src="topUrl"></el-image>
      </div>
      <div @click.stop="pageSetup" class="navBar x-f" :class="{navBar2:isImmersiveTop}">
        <i class="x-f-start" style="font-size: 25px; width: 90px"></i>
        <span class="title">{{ !isImmersiveTop ? ( pageDataObj.title || headline ) : ''}}</span>
        <el-image class="capsule x-f-end" :src="topUrl2"></el-image>
      </div>
      <div class="main" id="main" :style="{backgroundColor:!isImmersiveTop ? (pageDataObj.bgColor||'') : '',backgroundImage:(!isImmersiveTop &&pageDataObj.bgUrl) ? `url(${pageDataObj.bgUrl})` : ''}">
        <!-- :move=“onMoveCeshi” 表示不能被停靠，其他元素不可以与当前元素调换位置 -->
        <draggable :list="$store.state.template.tempData" :move="draggableOnMove" filter=".unmover">
          <template v-for="(item, index) in $store.state.template.tempData" style="position: relative;">
            <div v-if="item && item.name" :key="`${index}.${item.name}`" class="item"
               :class="{
                unmover: (item.name === 'dibudaohang')||(item.name === 'navigation'),
                tipsBox:  item.name === 'tanchuang',
                kefuBox:  item.name === 'kefu',
                sosuoBox:  item.name === 'sousuo' && $store.state.template.tempData.findIndex(e=> e&&( e.name == 'sousuo')) != '-1' ? $store.state.template.tempData[$store.state.template.tempData.findIndex(e=> e&&( e.name == 'sousuo'))].style.isFixed : false,
              }" @click="tempClick(index)" :style="{border: $store.state.template.selectIndex == index ? '1px solid #63a1ff' : ''}">
              <!-- 删除按钮 -->
              <div v-if="$store.state.template.selectIndex == index" class="cardBtns cursorP">
                <div class="cardBtns-item" @click.stop="changeItem(index, -1)" v-if="!['navigation','immersive'].includes(item.name)">
                  <i class="el-icon-arrow-up"></i>
                </div>
                <div class="cardBtns-item" @click.stop="changeItem(index, 1)" v-if="!['navigation','immersive'].includes(item.name)">
                  <i class="el-icon-arrow-down"></i>
                </div>
                <div class="cardBtns-item" @click.stop="deleteItem(index)">
                  <i class="el-icon-delete"></i>
                </div>
              </div>
              <component :keys="`${new Date()}`" :data="item" :is="item.name" />
            </div>
          </template>
        </draggable>
      </div>
    </div>
  </div>
</template>
<script>
import axios from 'axios'; // 假设你已经安装了axios
import draggable from 'vuedraggable'
import Vuex from 'vuex'
import Navigation from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/navigation.vue'
import Goods from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/goods.vue' //展示商品样式组件
import Wenben from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/wenben.vue'
import Mofang from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/mofang.vue'
import Fengefuzhu from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/fengefuzhu.vue'
import Gonggao from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/gonggao.vue'
import Sousuo from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/sousuo.vue'
import ImageNav from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/imageNav.vue' //图文导航
import Guanggao from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/guanggao.vue'
import Kefu from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/kefu.vue'
import VideoTmp from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/videoTmp.vue'
import Zhangfen from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/zhangfen.vue'
import Zichan from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/zichan.vue'
import Coupon from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/coupon.vue'
import Pintuan from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/pintuan.vue'
import Miaosha from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/miaosha.vue'
import Zhekou from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/zhekou.vue'
import Gexinghua from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/gexinghua.vue'
//商品图
import Shangpintu from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpintu.vue'
//商品信息
import Shangpinxinxi from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpinxinxi.vue'
//商品规格
import Shangpinguige from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpinguige.vue'
//商品评价
import Shangpinpingjia from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpinpingjia.vue'
//商品详情
import Shangpinxiangqing from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpinxiangqing.vue'
//底部导航
import Dibudaohang from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/dibudaohang.vue'
//服务方式
import Fuwufangshi from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/fuwufangshi.vue'
//商品导航
import Shangpindaohang from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/shangpindaohang.vue'
//标题栏
import Biaotilan from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/biaotilan.vue'
//会员信息
import Huiyuanxinxi from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/huiyuanxinxi.vue'
//会员信息
import Liebiaodaohang from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/liebiaodaohang.vue'
//图标组
import Tubiaozu from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/tubiaozu.vue'
//按钮组
import Anniuzu from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/anniuzu.vue'
//砍价
import Kanjia from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/kanjia.vue'
//PLUS会员
import Plusvip from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/plusVip.vue'
//悬浮组件
import tanchuang from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/tanchuang.vue' //图文导航
// 沉浸式轮播图
import Immersive from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/immersive.vue'

import rubikcube from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/rubikcube.vue'
import switching from '@/views/O2OMall/config/template/decoratePage/components/conterTemplate/switching.vue'

import { deepCopy } from '@/utils'
import html2canvas from 'html2canvas';
export default {
  name: 'conterPreview',
  components: {
    switching,
    rubikcube,
    Immersive,
    draggable,
    Goods,
    Wenben,
    Fengefuzhu,
    Gonggao,
    Sousuo,
    ImageNav,
    Guanggao,
    Kefu,
    VideoTmp,
    Zhangfen,
    Zichan,
    Coupon,
    Pintuan,
    Miaosha,
    Zhekou,
    Gexinghua,
    Shangpintu,
    Shangpinxinxi,
    Shangpinguige,
    Shangpinpingjia,
    Shangpinxiangqing,
    Dibudaohang,
    Fuwufangshi,
    Shangpindaohang,
    Biaotilan,
    Huiyuanxinxi,
    Liebiaodaohang,
    Tubiaozu,
    Anniuzu,
    Mofang,
    Kanjia,
    Plusvip,
    tanchuang,
    Navigation
  },
  props: {
    templateType:{
      type: [Number,String],
      default: 1
    },
    headline: {
      type: String,
      default: '商城首页'
    }
  },
  data() {
    return {
      actionUrl: process.env.VUE_APP_BASE_API + "/api/open/oss/file/upload",
      enterItem: null ,// 鼠标移入边框高亮
      scrollContainer:800
    }
  },
  watch: {
    '$store.state.template.tempData':{
      handler(val) {
        this.updatePreviewHeight()
        console.log('变化了',this.scrollContainer);
      }, deep: true, immediate: true
    }
  },
  computed: {
    isImmersiveTop(){
      const boo = this.$store.state?.template?.tempData?.[0]?.name == 'immersive'
      return boo
    },
    pageDataObj() {
      let data = this.$store.state?.template?.tempData?.find(v=> v?.name === 'pageData')
      if (!data) {
        data = {
          name: 'pageData',
          style: {
            bgColor:'#f5f5f5',
            title:undefined,
            topBGTypeObj:{
              type:1,
              bgColor:'',
              url:''
            },
            capsuleType:1
          },
        }
      }
      return data.style;
    },
    topUrl(){
      return this.pageDataObj?.capsuleType == 2 ? 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-12/f092527ed82b41e3b88115f5b613baa4.png' : 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-12/a498895665304fbeb3d5bea25b168a8a.png'
    },
    topUrl2(){
      return this.pageDataObj?.capsuleType == 2 ? 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-12/932b719a89544f8fb6766a5ad414af4c.png' : 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2024-12-12/6cf2d16fe8674dae9aa2bb4ed53bea2e.png'
    }
  },
  mounted() {
      this.updatePreviewHeight();
      window.addEventListener('resize', this.updatePreviewHeight);
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.updatePreviewHeight);
  },
  methods: {
    ...Vuex.mapActions({
      setTemplateRightData: 'setTemplateRightData',
      changeIndex: 'changeIndex'
    }),
   pageSetup(){
      if (this.templateType != 1) return
      this.$store.state.template.selectIndex = null
      let data = this.$store.state?.template?.tempData?.find(v=> v?.name === 'pageData')
      if (!data) {
        data = {
          name: 'pageData',
          style: {
            bgColor:'#f5f5f5',
            title:undefined,
            topBGTypeObj:{
              type:1,
              bgColor:'',
              url:''
            },
            capsuleType:1
          },
        }
      }
      this.$store.state.template.tempData.push(data);
      this.setTemplateRightData(data)
    },
  async updatePreviewHeight() {
      const awaitTime = (t = 100) => new Promise((resolve) => setTimeout(resolve, t));
      await awaitTime(500);
      this.$nextTick()
      this.scrollContainer = document.getElementById('main')?.clientHeight;
    },
    generateImage() {
      const element = document.getElementById('preview');
      const images = element.getElementsByTagName('img');
      let loadedImages = 0;

      return new Promise((resolve, reject) => {
        // 更新加载完成的图片数量
        function checkImagesLoaded() {
          if (loadedImages === images.length) {
            captureElement();
          }
        }

        function imageLoaded() {
          loadedImages++;
          checkImagesLoaded();
        }

        for (let i = 0; i < images.length; i++) {
          if (images[i].complete) {
            imageLoaded();
          } else {
            images[i].onload = imageLoaded;
            // 添加错误处理以防图片加载失败
            images[i].onerror = reject;
          }
        }

        function captureElement() {
          html2canvas(element, { useCORS: true }).then((canvas) => {
            const imageBase64 = canvas.toDataURL('image/png');
            resolve(imageBase64); // 解析Promise为Base64字符串
          }).catch(reject); // 传递任何html2canvas错误
        }

        // 如果一开始就没有图片要加载，立即捕获元素
        if (images.length === 0) {
          captureElement();
        }
      });
    },
    generateAndUploadImage() {
      const element = document.getElementById('preview');
      html2canvas(element, { useCORS: true }).then((canvas) => {
        const image = canvas.toDataURL('image/png');
        const blob = this.dataURLtoBlob(image); // 将DataURL转换为Blob对象
        const formData = new FormData(); // 创建FormData对象以准备上传数据
        formData.append('file', blob, 'image.png'); // 将Blob对象添加到FormData中，并指定文件名

        // 配置并发送上传请求到后端接口
        axios.post(this.actionUrl, formData, {
          headers: {
            'Content-Type': 'multipart/form-data' // 设置请求头为multipart/form-data以支持文件上传
          }
        }).then(response => {
          // 处理响应数据，例如显示上传成功的消息或处理返回的文件URL等
          console.log(response.data);
        }).catch(error => {
          // 处理上传错误，例如显示错误消息等
          console.error(error);
        });
      });
    },
    // 辅助函数：将DataURL转换为Blob对象
    dataURLtoBlob(dataURL) {
      const arr = dataURL.split(',');
      const mime = arr[0].match(/:(.*?);/)[1];
      const bstr = atob(arr[1]);
      let n = bstr.length;
      const u8arr = new Uint8Array(n);
      while (n--) {
        u8arr[n] = bstr.charCodeAt(n);
      }
      return new Blob([u8arr], { type: mime });
    },
    tempClick(index) {
      this.$store.state.template.selectIndex = index
      // this.enterItem = null
      this.setTemplateRightData(this.$store.state.template.tempData[index])
    },
    // 鼠标移入
    enter(index) {
      if (this.$store.state.template.selectIndex != index) {
        this.enterItem = index
      }
    },
    // 鼠标移出
    leave() {
      this.enterItem = null
    },
    deleteItem(index) {
      console.log('删除组件', index)
      console.log('-=-=-=-=-:', this.$store.state.template.tempData[index])
      this.$store.commit(
        'DELETE_UPDATA_NUM',
        this.$store.state.template.tempData[index].name
      )
      this.$store.state.template.tempData.splice(index, 1)
      if (index > 0) {
        this.$store.state.template.selectIndex = index - 1
        this.setTemplateRightData(
          this.$store.state.template.tempData[
          this.$store.state.template.selectIndex
          ]
        )
      } else {
        this.$store.state.template.selectIndex = -1
        this.setTemplateRightData({})
      }
    },
    changeItem(index, toIndex) {
      const i = index + toIndex
      if (toIndex == -1 && this.$store.state?.template?.tempData?.[i]?.name == 'immersive') return this.$message.warning('不能移动到沉浸式模板顶部！')
      if (i >= 0 && i <= this.$store.state.template.tempData.length - 1) {
        const data = deepCopy(this.$store.state.template.tempData)
        const changeItem = data[i]
        data[i] = data[index]
        data[index] = changeItem
        this.$store.state.template.tempData = data
        this.$nextTick(() => {
          this.changeIndex(i)
        })
      }
    },
    draggableOnMove(e) {
      //relatedContext:将停靠的对象，设置为false.表示不允许停靠和拖拽
      let name = e.relatedContext.element.name
      if ((name === 'dibudaohang')||(name === 'navigation')) return false
      return true
    }
  },
  beforeDestroy() {
    this.$store.state.template.selectIndex = 0
    this.$store.state.template.tempData = []
  }
}
</script>

<style lang="scss" scoped>
.phoneBox {
  position: relative;

  .labelBox {
    position: absolute;
    right: 0;
    top: 100px;
    width: 100px;
    background-color: #ffffff;
    border: #bebebe solid 1px;
    border-bottom: none;
  }
}

.phone {
  background-size: 100% auto;
  background-repeat: no-repeat;
  width: 375px;
  min-width: 375px;
  min-height: 800px;
  margin: 30px auto;
  background-color: #f5f5f5;
  border: 1px solid #eaeaea;
  position: relative;
  .phoneStartBox {
    width: 100%;
    height: 45px;
  }
  .phoneStartBox2{
    position: absolute; 
    top: 0;
    left: 0;
    z-index: 999;
  }
  .navBar2{
    position: absolute; 
    top: 45px;
    left: 0;
    z-index: 999;
  }
  .navBar {
    width: 100%;
    height: 44px;
    padding: 0 10px;
    // border-bottom: 1px solid #dcdcdc;
    .title {
      font-size: 18px;
      font-weight: 550;
      margin: 0 auto;
    }

    .capsule {
      width: 87px;
      height: 33px;
    }
  }

  .main {
    border: 1px solid #eaeaea;
    .item {
      cursor: move;
      position: relative;

      .cardBtns {
        position: absolute;
        top: 0;
        left: calc(100% + 5px);
        font-size: 18px;
        z-index: 999;
        background-color: #ffffff;
        box-shadow: 1px 2px 10px 5px #dedede;
        .cardBtns-item {
          height: 40px;
          width: 40px;
          padding: 10px;
          i {
            margin: auto;
            font-size: 18px !important;
            text-align: center;
          }
          &:hover {
            background-color: #f5f5f5;
          }
        }
      }
    }

    // .enterBorder {
    //   border: 1px dashed #63a1ff;
    // }
  }
}
.tipsBox {
  position: fixed !important;
  z-index: 10;
  right: 44.6%;
  top: 60%;
  border: none !important;
}
.sosuoBox {
  position: absolute !important;
  z-index: 10;
  top: 90px;
  left: 0;
  width: 100%;
}
.kefuBox {
  position: absolute !important;
  z-index: 10;
  right: 0px;
  width: 100%;
  top: 350px;
  padding-right: 13px;
  border: none !important;
}
</style>
