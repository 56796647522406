<template>
    <div class="wrap x-bc" :style="{paddingTop: `${data.style.pagePaddingTop? data.style.pagePaddingTop : '0'}px`, paddingRight: `${data.style.pagePaddingRight? data.style.pagePaddingRight : '0'}px`, paddingBottom: `${data.style.pagePaddingBottom? data.style.pagePaddingBottom : '0'}px`, paddingLeft: `${data.style.pagePaddingLeft? data.style.pagePaddingLeft : '0'}px`}">
        <div v-if="data.styleImage == 'fixed'" :style="{backgroundColor: data.style.bgColor, borderRadius: `${data.style.pageImgRadius}px`}" style="width: 100%" class="x-bc">
            <div
                v-for="(item, index) in data.immageNavList"
                :key="index"
                class="y-c"
                :style="{width:  `${100 / data.immageNavList.length}%`}"
                style="padding: 10px"
            >
                <div v-if="data.textFormworkNav == 'imageFormwork'">
                    <el-image
                        v-if="item.imgUrl != ''"
                        style="width: 38px; height: 38px"
                        :src="item.imgUrl"
                    >
                    </el-image>
                    <div v-else>
                        <el-image
                            style="width: 38px; height: 38px"
                            src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png"
                        ></el-image>
                    </div>
                </div>

                <span class="title x-c" :style="{ color: data.style.textColor }">{{ item.title }}</span>
            </div>
        </div>
        <div class="swipe x-bc" :style="{backgroundColor: data.style.bgColor, borderRadius: `${data.style.pageImgRadius}px`}" style="width: 100%" v-else-if="data.styleImage == 'roll'">
            <el-scrollbar>
                <div
                    v-for="(item, index) in data.immageNavList"
                    :key="index"
                    class="y-c"
                    :style="[{width:  `200px`}]"
                    style="padding: 10px"
                >
                    <div v-if="data.textFormworkNav == 'imageFormwork'">
                        <el-image
                            v-if="item.imgUrl != ''"
                            style="width: 38px; height: 38px"
                            :src="item.imgUrl"
                        >
                        </el-image>
                        <div v-else style="padding: 7px">
                            <el-image
                                style="width: 38px; height: 38px"
                                src="https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png"
                            ></el-image>
                        </div>
                    </div>

                    <span class="title x-c" style="padding: 0 7px" :style="{ color: data.style.textColor }"
                    >{{ item.title }}</span>
                </div>
            </el-scrollbar>
        </div>
    </div>
</template>

<script>
export default {
    name: 'imageNav',
    props: {
        data: {
            type: Object,
            default: {}
        }
    },
    data() {
        return {
            src: 'https://balingsoft-oss.oss-cn-shenzhen.aliyuncs.com/file/2023-06-15/0c878c59403b4a7fb461b4cb073047f4.png'
        }
    },
    created() {
        console.log('stype-----', this.data.immageNavList)
    }
}
</script>

<style lang="scss" scoped>
//整体
.wrap {
    width: 100%;
    box-sizing: border-box;

    .title {
        width: 80px;
        margin: 3px 0;
        min-height: 16px;
    }
}

//横向滑动
.swipe {
    //滚动条
    ::v-deep .el-scrollbar__wrap {
        overflow-x: auto;
    }

    ::v-deep .el-scrollbar .el-scrollbar__wrap .el-scrollbar__view {
        white-space: nowrap;
        display: flex;
    }

    .goodsItem {
        flex-grow: 0;
        flex-shrink: 0;
        flex-basis: 30%;
    }
}
</style>
